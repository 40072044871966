@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.custom-slider .slick-dots li button:before {
  display: flex !important;
}

.custom-slider .slick-dots li button:before {
  top: 30px;
  color: white;
}

.custom-slider .slick-dots li.slick-active button:before {
  color: white !important;
}

.custom-slider .slick-slide img {
  display: initial !important;
}

.custom-slider .slider-prev-arrow svg {
  rotate: 180deg;
}

.custom-slider .slider-next-arrow,
.custom-slider .slider-prev-arrow {
  cursor: pointer;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #000;
  border: 2px solid #000;
  transition: all 0.2s ease-in-out;
}

.custom-slider .slider-next-arrow:hover,
.custom-slider .slider-prev-arrow:hover {
  background-color: #000;
}

.custom-slider .slider-next-arrow:hover svg path,
.custom-slider .slider-prev-arrow:hover svg path {
  fill: #fff;
}

.custom-slider .slider-next-arrow {
  right: -2rem;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-slider .slider-prev-arrow {
  left: 0rem;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.custom-slider .slider-next-arrow span,
.custom-slider .slider-prev-arrow span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-slider .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.custom-slider .slick-slide > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-slider .slick-slide:not(:last-of-type) > div {
  padding-inline-end: 0.5rem;
}

.custom-slider .slick-slide:last-of-type > div {
  padding-inline-end: 2px;
}

.custom-slider .slick-track {
  margin-left: 0;
  margin-right: 0;
}
