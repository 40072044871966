.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: linear-gradient(90deg, #44c9ff 0%, #960bcd 100%) !important;
  border: none !important;
}

.ant-btn-primary {
  background: linear-gradient(90deg, #44c9ff 0%, #960bcd 100%) !important;
  border: none !important;
  padding: 8px 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 200px;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none !important;
}
