.summary-preview {
  margin-top: 2rem;
  line-height: 1.5;
}

.slider-card-holder {
  position: relative;
  padding: 1rem 0;
}

.slider-card-holder .slider-card {
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 16px;
  border: 1px solid #eee;
  display: flex;
  align-items: flex-end;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: 1;
}

.slider-card-holder .slider-card:hover {
  opacity: 0.9;
}

.slider-card-holder .slider-card .slider-card-header {
  position: absolute;
  bottom: 70px;
  padding: 0.5rem;
  background: linear-gradient(transparent, #000);
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
}

.slider-card-holder .slider-card .slider-card-header .ant-typography {
  color: #fff;
  font-size: 2.5rem;
}

.slider-card-holder .slider-card .slider-card-info {
  background-color: #fff;
  width: 100%;
  height: 70px;
  padding: 6px 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 14px 14px;
  border: 1px solid #eee;
}

.summary-preview img {
  max-width: 100%;
}

.seconde-slider-type .custom-slider .slick-track .slick-current > div {
  padding-inline-end: 2.5rem;
}

@media (max-width: 768px) {
  .seconde-slider-type .custom-slider .slick-track .slick-current > div {
    padding-inline-end: 0.5rem;
  }
}
