#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-sider .layout-sidebar-logo {
  display: flex;
  align-items: center;
  padding-inline-start: 24px !important;
  margin: 10px 0;
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1200px !important;
  }
}

@media (max-width: 1400px) {
  .container {
    max-width: 1300px !important;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px !important;
  }
}
